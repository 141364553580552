import React, { useState } from 'react';
import "./sidebar.scss";
import logo from '../../assets/img_logo.png';
import { ReactComponent as DashboardIcon } from '../../assets/ic_dashboard.svg';
import { ReactComponent as UsersIcon } from '../../assets/ic_users.svg';
import { ReactComponent as BookingIcon } from '../../assets/ic_booking.svg';
import { ReactComponent as TransactionIcon } from '../../assets/ic_transactions.svg';
import { ReactComponent as ApplicationsIcon } from '../../assets/ic_applications.svg';
import { ReactComponent as GiftIcon } from '../../assets/ic_gift.svg';
import { ReactComponent as ManageNotificationsIcon } from '../../assets/ic_manage_notifications.svg';
import { ReactComponent as LogoutIcon } from '../../assets/ic_logout.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/ic_chevron_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/ic_chevron_up.svg';
import { ReactComponent as SettingsIcon } from '../../assets/ic_settings.svg';
import { ReactComponent as LogsIcon } from '../../assets/ic_logs.svg';
import { useNavigate } from 'react-router-dom';
import { onLogout } from '../../axiosConfig';

const Sidebar = (params) => {
    const [navChild, setNavChild] = useState(params.navChild != null ? params.navChild : {});
    const navigate = useNavigate();
    const handleSidebarClick = (sidebarID, sidebarPath) => {
        if (sidebarID === "logout") {
            onLogout();
        } else {
            navigate(sidebarPath);
            if (params.page === sidebarID) {
                params.onSidebarClick();
            }
        }
    };
    const SidebarLayout = (props) => {
        let propsID = props.id;
        return (
            <div>
                <div className={`nav-option option1 ${!props.showArrow ? params.page === propsID ? 'nav-icon-selected' : 'nav-icon-unselected' : ''}`} onClick={() => !props.showArrow ? handleSidebarClick(props.id, props.path) : setNavChild(prevNavChild => ({ ...prevNavChild, [propsID]: !navChild[propsID] }))}>
                    <div className='nav-option-icon-container'>
                        {props.icon != null ? <props.icon className='nav-option-icon' /> : ""}
                    </div>
                    {props.name}
                    {props.showArrow ? (
                        navChild[propsID] ? <ArrowUpIcon className='nav-option-arrow-icon' /> : <ArrowDownIcon className='nav-option-arrow-icon' />
                    ) : ""}
                </div>
                {
                    navChild[propsID] ? props.childItem.map((child, key) => {
                        return (
                            <div key={key} className={`nav-option option1 ${params.page === child.childItemID ? 'nav-icon-selected' : 'nav-icon-unselected'}`} onClick={() => handleSidebarClick(child.childItemID, child.childItemPath)}>
                                <div className='nav-option-icon-container'></div>
                                {child.childItemName}
                            </div>
                        )
                    }) : ""
                }
            </div>
        )
    }

    return (
        <div className='sidebar-content'>
            <div className='sidebar-option-container'>
                <img className='sidebar-logo' src={logo} alt="Logo" />
                <div className='sidebar-option'>
                    {/* <SidebarLayout id="dashboard" name="Dashboard" icon={DashboardIcon} path="/dashboard" /> */}
                    <SidebarLayout id="setup" name="Setup" icon={SettingsIcon} showArrow={true}
                        childItem={[
                            { childItemID: "booking_promotions", childItemName: "Booking Promotions", childItemPath: "/setup/booking-promotions" },
                            { childItemID: "faqs", childItemName: "FAQs", childItemPath: "/setup/faqs" },
                            { childItemID: "agent_banners", childItemName: "Agent Banners", childItemPath: "/setup/agent_banners" }
                        ]}
                    />
                    <SidebarLayout id="accounts" name="Accounts" icon={UsersIcon} showArrow={true}
                        childItem={[
                            { childItemID: "admins", childItemName: "Admins", childItemPath: "/admins" },
                            { childItemID: "customers", childItemName: "Customers", childItemPath: "/customers" },
                            { childItemID: "agents", childItemName: "Agents", childItemPath: "/agents" },
                            { childItemID: "deletion_request", childItemName: "Deletion Request", childItemPath: "/deletion_request" }
                        ]}
                    />
                    <SidebarLayout id="services" name="Services" icon={ApplicationsIcon} path="/services" />
                    <SidebarLayout id="bookings" name="Bookings" icon={BookingIcon} path="/bookings" />
                    <SidebarLayout id="sessions" name="Booked Sessions" icon={BookingIcon} path="/sessions" />
                    <SidebarLayout id="transactions" name="Transactions" icon={TransactionIcon} showArrow={true}
                        childItem={[
                            { childItemID: "transactions_in", childItemName: "In", childItemPath: "/transactions/in" },
                            { childItemID: "transactions_out", childItemName: "Out", childItemPath: "/transactions/out" },
                        ]}
                    />
                    {/* <SidebarLayout id="redemption" name="Redemption" icon={GiftIcon} showArrow={true}
                        childItem={[
                            { childItemID: "redemption_vouchers", childItemName: "Vouchers", childItemPath: "/redemption/voucher" },
                            { childItemID: "redemption_history", childItemName: "History", childItemPath: "/redemption/history" },
                        ]}
                    /> */}
                    <SidebarLayout id="manage_notifications" name="Notifications" icon={ManageNotificationsIcon} showArrow={true}
                        childItem={[
                            { childItemID: "notification_template", childItemName: "Templates", childItemPath: "/notifications/template" },
                            { childItemID: "notification_history", childItemName: "History", childItemPath: "/notifications/history" },
                        ]}
                    />
                    <SidebarLayout id="logs" name="Logs" icon={LogsIcon} path="/logs" />
                    <SidebarLayout id="logout" name="Logout" icon={LogoutIcon} />
                </div>
            </div>
        </div>
    )
}

export default Sidebar