import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import { createContext, useState } from 'react';
import './App.css';
import Users from './pages/users/Users';
import Bookings from './pages/bookings/Bookings';
import BookingDetails from './pages/bookings/BookingDetails';
import SessionDetails from './pages/sessions/SessionDetails';
import Services from './pages/services/Services';
import AddEditService from './pages/services/AddEditService';
import ServiceDetails from './pages/services/ServiceDetails';
import NotificationTemplate from './pages/notifications/template/NotificationTemplate';
import NotificationHistory from './pages/notifications/history/NotificationHistory';
import NotificationHistoryDetails from './pages/notifications/history/NotificationHistoryDetails';
import AddEditNotificationTemplate from './pages/notifications/template/AddEditNotificationTemplate';
import AccountDeletion from './pages/accountDeletion/AccountDeletion';
import SmoothScroll from './components/SmoothScroll';
import NotFound from './pages/notFound/NotFound';
import Login from './pages/login/Login';
import UserDetails from './pages/users/UserDetails';
import AddUser from './pages/users/AddUser';
import Sessions from './pages/sessions/Sessions';
import Transactions from './pages/transactions/Transactions';
import TransactionDetails from './pages/transactions/TransactionDetails';
import EditFAQs from './pages/setup/faq/EditFAQs';
import Promotions from './pages/setup/promotion/Promotions';
import EditPromotions from './pages/setup/promotion/EditPromotions';
import FAQs from './pages/setup/faq/FAQs';
import Logs from './pages/logs/Logs';
import { Helmet } from 'react-helmet';
import AgentBanners from './pages/setup/agentBanners/AgentBanners';
import EditAgentBanners from './pages/setup/agentBanners/EditAgentBanners';
import RedemptionHistory from './pages/redemption/history/RedemptionHistory';
import RedemptionVoucher from './pages/redemption/voucher/RedemptionVoucher';
import RedemptionVoucherDetails from './pages/redemption/voucher/RedemptionVoucherDetails';
import AddRedemptionVoucher from './pages/redemption/voucher/AddRedemptionVoucher';

export const AppContext = createContext();
function App() {
  const [navClose, setNavClose] = useState(false);

  return (
    <div className="app">
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      <HashRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/">
              <Route index path='login' element={<Login />} />
              {/* <Route path='dashboard' element={<Dashboard navClose={navClose} setNavClose={setNavClose} />} /> */}

              <Route path='setup/booking-promotions' element={<Promotions navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='setup/booking-promotions/promotions-edit' element={<EditPromotions navClose={navClose} setNavClose={setNavClose} action="edit" />} />
              <Route path='setup/faqs' element={<FAQs navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='setup/faqs/faqs-edit' element={<EditFAQs navClose={navClose} setNavClose={setNavClose} action="edit" />} />
              <Route path='setup/agent_banners' element={<AgentBanners navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='setup/agent_banners/agent-banners-edit' element={<EditAgentBanners navClose={navClose} setNavClose={setNavClose} action="edit" />} />

              <Route path='services' element={<Services navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='service/details' element={<ServiceDetails navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='service/:id/service-edit' element={<AddEditService navClose={navClose} setNavClose={setNavClose} action="edit" />} />
              <Route path='service/service-add' element={<AddEditService navClose={navClose} setNavClose={setNavClose} action="add" />} />

              <Route path='bookings' element={<Bookings navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='booking/details' element={<BookingDetails navClose={navClose} setNavClose={setNavClose} />} />

              <Route path='sessions' element={<Sessions navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='session/details' element={<SessionDetails navClose={navClose} setNavClose={setNavClose} />} />

              <Route path='transactions/in' element={<Transactions navClose={navClose} setNavClose={setNavClose} type="in" />} />
              <Route path='transactions/out' element={<Transactions navClose={navClose} setNavClose={setNavClose} type="out" />} />
              <Route path='transaction/details' element={<TransactionDetails navClose={navClose} setNavClose={setNavClose} />} />

              <Route path='admins' element={<Users navClose={navClose} setNavClose={setNavClose} type="admins" />} />
              <Route path='admins/details' element={<UserDetails navClose={navClose} setNavClose={setNavClose} type="admins" />} />

              <Route path='customers' element={<Users navClose={navClose} setNavClose={setNavClose} type="customers" />} />
              <Route path='customers/details' element={<UserDetails navClose={navClose} setNavClose={setNavClose} type="customers" />} />

              <Route path='agents' element={<Users navClose={navClose} setNavClose={setNavClose} type="agents" />} />
              <Route path='agents/details' element={<UserDetails navClose={navClose} setNavClose={setNavClose} type="agents" />} />
              <Route path='agents/agent-add' element={<AddUser navClose={navClose} setNavClose={setNavClose} action="add" type="agents" />} />

              {/* <Route path='redemption/voucher' element={<RedemptionVoucher navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='redemption/voucher/details' element={<RedemptionVoucherDetails navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='redemption/voucher-add' element={<AddRedemptionVoucher navClose={navClose} setNavClose={setNavClose} action="add" />} /> */}

              <Route path='redemption/history' element={<RedemptionHistory navClose={navClose} setNavClose={setNavClose} />} />

              <Route path='notifications/template' element={<NotificationTemplate navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='notifications/template/:id' element={<NotificationHistoryDetails navClose={navClose} setNavClose={setNavClose} type="template" />} />
              <Route path='notifications/template-add' element={<AddEditNotificationTemplate navClose={navClose} setNavClose={setNavClose} action="add" />} />
              <Route path='notifications/:id/template-edit' element={<AddEditNotificationTemplate navClose={navClose} setNavClose={setNavClose} action="edit" />} />

              <Route path='notifications/history' element={<NotificationHistory navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='notifications/history/:id' element={<NotificationHistoryDetails navClose={navClose} setNavClose={setNavClose} type="history" />} />

              <Route path='logs' element={<Logs navClose={navClose} setNavClose={setNavClose} />} />

              <Route path='deletion_request' element={<AccountDeletion navClose={navClose} setNavClose={setNavClose} />} />
              <Route path='*' exact={true} element={<NotFound />} />
              <Route path='' element={<Navigate to="login" />} />
            </Route>
          </Routes>
        </SmoothScroll>
      </HashRouter>
      <div id="errorMessageAlert" style={{ zIndex: 99999 }}></div>
    </div>
  );
}

export default App;